<template>
    <div id="fit-window" ref="fitWindow">
        <div v-if="waiting" class="w-100 text-center mt-4">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <h4>{{status}}</h4>
        </div>
        <div v-else id="table-holder">
            <div id="collapse-results">
                <table class="table table-striped" id="lb-table" >
                    <thead class="thead-dark sticky-header lb-table-th" >
                        <draggable v-model="fields" tag="tr">
                            <th class="delimited py-0 px-0 align-middle" v-for="header in fields" :key="header.id" scope="col" :class="(header.name == sortBy) ? 'green-th': ''">
                                <div class="text-center pl-2 pr-2 header-inline">
                                    {{ header.name }}
                                </div>
                                <div class="header-inline">
                                    <b-icon-dash v-if="sortBy != `${header.name}`"></b-icon-dash>
                                    <b-icon-arrow-down v-else-if="sortBy == `${header.name}` && sortDesc"></b-icon-arrow-down>
                                    <b-icon-arrow-up v-else-if="sortBy == `${header.name}` && !sortDesc"></b-icon-arrow-up>
                                </div>
                            </th>
                        </draggable>
                    </thead>
                    <tbody>
                        <tr v-for="item in limitedItemsForDisplay" :key="item.id">
                            <td v-for="header in fields" :key="header.id + '-' + item.id" class="lb-table-td">
                                <span class="mx-0 my-0">{{ item[header.name] }}</span>
                            </td>
                        </tr>
                        <tr v-if="moreThanLimitItems">
                            <td :colspan="fields.length" class="more-than-row">More than {{ setLimit }} rows... download CSV to see all rows.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-if="issuable && !weHaveBegun && !weAreComplete" class="fulcrum-info">
            <b-form v-on:submit.prevent>
                <b-col sm="12" xl="12">
                    <b-row>
                        <b-col>
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-app-name-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select Fulcrum App:'"
                                :label-for="'fulcrum-issue-modal-select-app-name-' + id"
                            >

                                <b-form-input
                                    :id="'fulcrum-issue-modal-select-app-name-' + id"
                                    v-model="appSelected"
                                    :list="'fulcrum-app-datalist-' + id"
                                    :required="true"
                                    :multiple="false"
                                    :state="appSelectionValid"
                                    @input="touchApp($event)"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-datalist :id="'fulcrum-app-datalist-' + id" :options="formNamesForDataList"></b-form-datalist> 
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-product-code-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select Product Code:'"
                                :label-for="'fulcrum-issue-modal-select-product-code-' + id"
                            >

                                <b-form-input
                                    :id="'fulcrum-issue-modal-select-product-code-' + id"
                                    v-model="productCodeSelected"
                                    :list="'fulcrum-issue-modal-product-code-datalist-' + id"
                                    :required="true"
                                    :multiple="false"
                                    :state="productSelectionValid"
                                    @input="touchProductCode($event)"
                                >
                                </b-form-input>
                            </b-form-group>
                            <b-form-datalist :id="'fulcrum-issue-modal-product-code-datalist-' + id" :options="productCodeOptions"></b-form-datalist>
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-status-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select Status:'"
                            >
                            
                                <b-form-radio v-for="(status, index) in statusOptions" :key="index"
                                    :id="'fulcrum-issue-modal-select-status-' + index + '-' + id"
                                    v-model="statusSelected"
                                    name="select-status"
                                    :required="true"
                                    :state="statusSelectionValid"
                                    :value="status.value"
                                    @input="touchStatus($event)"
                                >
                                    <div :style="'color:' + status.textColor + '; background-color: ' + status.bgColor + ';'">
                                        {{status.label}}
                                    </div>
                                </b-form-radio>
                            </b-form-group >
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-tasks-label-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'Select Job Tasks:'"
                                :label-for="'fulcrum-issue-modal-select-tasks-' + id"
                            >
                            
                                <b-form-checkbox
                                    :id="'fulcrum-issue-modal-select-tasks-test-' + id"
                                    v-model="tasks.bfpaTest"
                                    name="bfpaTest"
                                    :required="false"
                                    :value="true"
                                >
                                    BFPA Test
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :id="'fulcrum-issue-modal-select-tasks-survey-' + id"
                                    v-model="tasks.survey"
                                    name="survey"
                                    :required="false"
                                    :value="true"
                                >
                                    Survey
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :id="'fulcrum-issue-modal-select-tasks-replacement-' + id"
                                    v-model="tasks.replacement"
                                    name="replacement"
                                    :required="false"
                                    :value="true"
                                >
                                    Replacement
                                </b-form-checkbox>
                            </b-form-group >
                            <b-form-group 
                                :id="'fulcrum-issue-modal-select-on-conflict-' + id"
                                label-cols-sm="4"
                                label-cols-lg="3"
                                description=""
                                :label="'On Conflict / Duplicate:'"
                            >
                            
                                <b-form-radio v-for="(action, index) in onConflictOptions" :key="index"
                                    :id="'fulcrum-issue-modal-select-on-conflict-' + index + '-' + id"
                                    v-model="onConflictAction"
                                    name="select-on-conflict"
                                    :required="true"
                                    :state="onConflictSelectionValid"
                                    :value="action.value"
                                    @input="touchOnConflict($event)"
                                >
                                    <div style="color: black;">
                                        {{action.text}}
                                    </div>
                                </b-form-radio>
                            </b-form-group >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="12" xl="12" v-if="readyToIssue && !weHaveBegun && !weAreComplete">
                    <b-button-toolbar justify>
                        <b-button variant="warning" @click="cancel">Cancel</b-button>
                        <b-button variant="success" @click="beginBulkIssue">Begin Bulk Issue</b-button>
                    </b-button-toolbar>
                </b-col>
            </b-form>
        </div>
        <div v-else-if="weHaveBegun">
            <b-col sm="12" xl="12">
                <b-progress :value="progress" :max="items.length" show-progress animated></b-progress>
            </b-col>
            <b-col sm="12" xl="12">
                <b-row>
                    <b-col cols="12">
                        <h3 v-if="weAreComplete">Bulk Issue Complete - Summary:</h3>
                        <h3 v-else>Bulk Issue In Progress - Summary:</h3>
                    </b-col>
                    <b-col sm="8" md="6" lg="4">
                        <h4>Completed</h4>
                    </b-col>
                    <b-col sm="4" md="6" lg="8">
                        {{issueResults.completed.length}}
                    </b-col>
                    <b-col sm="8" md="6" lg="4">
                        <h4>Failed</h4>
                    </b-col>
                    <b-col sm="4" md="6" lg="8">
                        {{issueResults.failed.length}}
                    </b-col>
                    <b-col sm="8" md="6" lg="4">
                        <h4>Skipped</h4>
                    </b-col>
                    <b-col sm="4" md="6" lg="8">
                        {{issueResults.skipped.length}}
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col cols="12">
                        <h4>Failed - {{issueResults.failed.length}}</h4>
                        <hr>
                    </b-col>
                    <b-col cols="12" v-for="(row, idx) in issueResults.failed" :key="idx">
                        <b-link target="_blank" :to="getFormLink(row['Device ID'], 'devices')">View Device</b-link> - Failed to Issue - <b-button v-if="!row._resolved" @click="reconcileFulcrumAndIssued(row)">Attempt To Resolve</b-button><span v-if="row._resolved">{{row._resolvedResponse}}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <h4>Skipped - {{issueResults.skipped.length}}</h4>
                        <hr>
                    </b-col>
                    <b-col cols="12" v-for="(row, index) in issueResults.skipped" :key="index"> 
                        <b-link target="_blank" :to="getFormLink(row['Device ID'], 'devices')">View Device</b-link> - Has {{row.activeIssued.length}} Outstanding {{(row.activeIssued.length == 1) ? "Record" : "Records"}}
                        <br>
                        <span class="ml-3" v-for="(issue, idx) in row.activeIssued" :key="idx">
                            Issued on {{formatDateWrapper(issue.issued_on)}} - <b-link target="_blank" :href="'https://web.fulcrumapp.com/records/' + issue.fulcrum_id">View In Fulcrum</b-link> <br>
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <h4>Completed - {{issueResults.completed.length}}</h4>
                        <hr>
                    </b-col>
                    <b-col cols="12" v-for="(row, idx) in issueResults.completed" :key="idx">
                        <b-link target="_blank" :to="getFormLink(row['Device ID'], 'devices')">View Device</b-link> - <b-link target="_blank" :href="row.fulcrumLink">View In Fulcrum</b-link>
                    </b-col>
                </b-row>
            </b-col>
        </div>
        <div v-else-if="!issuable" class="fulcrum-info">
            <h3>Not Eligible For Issue To Fulcrum</h3>
            <p>{{issuableReason}}</p>
        </div>
        
    </div>
</template>

<script>
// Modules
const _ = require('underscore');
const cloneDeep = require('lodash.clonedeep');
// Libraries
const butils = require('../../../libs/basicUtils.js');

import draggable from 'vuedraggable';

export default {
    name: 'listbuilderfulcrumbulkissue',
    components:{
        draggable,
    },
    props:{
        listID: {
            type: String,
            default: () => { return null }
        }
    },
    data(){
        return{
            issuable: false,
            issuableReason: '',
            weHaveBegun: false,
            weAreComplete: false,
            status: '',
            waiting: true,
            rawAvailableFields: [],
            tree: [],
            loadedFromPackage: null,
            savePackage:{
                id: null,
                name: null,
                description: null,
                disabled: false,
                permissions: 'user'
            },
            fields: [],
            items: [],
            filterElems: [],
            currentlySortedBy: null,
            sortBy: null,
            sortDesc: null,
            sortHeader: null,
            sortFunction: null,
            rawItems: [],
            items: [],
            limitedItemsForDisplay: [],
            setLimit: 1000,
            moreThanLimitItems: false,
            filterOperatorsPerType: {
                text: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'},
                    { text: 'contains (case-insensitive)', value:'CONTAINS'},
                    { text: 'does not contain (case-insensitive)', value:'NOTCONTAINS'}
                ],
                numeric: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                ],
                integer: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                ],
                'timestamp with time zone':[
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'more than X days ago', value: 'OLDERTHANDAYSAGO'},
                    { text: 'within the last X days', value: 'YOUNGERTHANDAYSAGO'},
                    { text: 'last month', value: 'LASTMONTH'},
                    { text: 'last 90 days', value: 'LAST90DAYS'},
                    { text: 'last year', value: 'LASTYEAR'},
                    { text: 'this year', value: 'THISYEAR'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                    
                ],
                'text[]':[
                    { text: 'includes the element (case-sensitive)', value: 'ARRAYCONTAINS'},
                    { text: 'includes an element like (case-insensitive)', value: 'ARRAYCONTAINSLIKE'},
                    { text: 'NOT including the element (case-sensitive)', value: 'ARRAYNOTCONTAINS'},
                    { text: 'NOT including an element like (case-insensitive)', value: 'ARRAYNOTCONTAINSLIKE'},
                    { text: 'Contains At Least One Element', value: 'ARRAYLENGTHATLEASTONE'},
                ],
                boolean: [
                    { text: 'is', value: 'EQUAL'},
                ]
            },
            booleanCompareToOptions: [
                { text: 'True', value: 'TRUE' },
                { text: 'False', value: 'FALSE' },
                { text: 'NULL (not applicable to all boolean fields)', value: 'NULL' }
            ],
            requiresUpdate: true,
            productCodeOptions: [],
            productCodeSelected: null,
            productSelectionValid: false,
            forms: [],
            formNamesForDataList: [],
            statusOptions: [],
            statusSelected: null,
            statusSelectionValid: false,
            appSelected: null,
            appIDSelected: null,
            appSelectionValid: null,
            tasks: [],
            onConflictOptions: [
                { value: 'ISSUE', text: 'Issue Duplicate Record To App'},
                { value: 'SKIP', text: 'Skip Duplicate, Don\'t Create A New Record'}
            ],
            onConflictAction: null,
            onConflictSelectionValid: false,
            readyToIssue: false,
            // Processing Data
            progress: 0,
            issueResults:{
                completed: [],
                failed: [],
                skipped: []
            }
        }
    },
    methods:{
        reconcileFulcrumAndIssued(row){
            var deviceID = row['Device ID'];
            var formID = this.appIDSelected;
            var packed = {
                deviceID: deviceID,
                formID: formID
            };
            butils.instance.post(process.env.VUE_APP_API_BASE_URL + `/atils/fulcrum/attemptfailedissuerecovery.json`, packed)
            .then((response)=>{
                var fixed = response.data.result.fixed;
                if(fixed.length > 0){
                    var theoreticallyThisOne = fixed[0].fulcrum_id;
                    row._resolved = true;
                    row._resolvedResponse = 'Fixed';
                    row.fulcrumLink = `https://web.fulcrumapp.com/records/${theoreticallyThisOne}`;
                    this.issueResults.completed.push(row);
                    this.issueResults.failed = this.issueResults.failed.filter((fail)=>{ return fail['Device ID'] != deviceID })
                }else{
                    console.log("Fixed Length is 0, not sure why this happened.")
                    console.log(fixed);
                    console.log(response.data);
                }
            })
            .catch((err)=>{
                console.log(err);
                row._resolved = true;
                row._resolvedResponse = 'Failed To Fix Automatically - Requires Meatbag Intervention';
            })

        },
        getFormLink(inspectID, isType){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: isType});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + inspectID
            }
        },
        beginBulkIssue(){
            this.issueResults = {
                completed: [],
                failed: [],
                skipped: []
            };
            this.weHaveBegun = true;
            this.process(0);
        },
        process(index){
            if(index < this.items.length){
                this.progress = index;
                // Check if this device is already issued
                var row = this.items[index];
                butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/atils/fulcrum/outstanding/${row["Device ID"]}.json`)
                    .then((response)=>{
                        var issueData = response.data.result;
                        var isOut = this.hasActiveRecord(issueData);
                        row.issueData = issueData;
                        if(isOut && this.onConflictAction == 'SKIP'){
                            // Dont Issue, Skip This One
                            var activeIssued = this.getActiveRecordData(row);
                            row.activeIssued = activeIssued;
                            this.issueResults.skipped.push(row);
                            this.process(index + 1);
                        }else{
                            // Issue This One
                            var issueRequestPacket = {
                                deviceID: row["Device ID"],
                                formID: this.appIDSelected,
                                status: this.statusSelected,
                                productCode: this.productCodeSelected,
                            };

                            if(this.tasks.bfpaTest || this.tasks.survey || this.tasks.replacement){
                                var tasks = [];
                                if(this.tasks.bfpaTest){
                                    tasks.push("BFPA Test");
                                }
                                if(this.tasks.survey){
                                    tasks.push("Survey");
                                }
                                if(this.tasks.replacement){
                                    tasks.push("BFPA Replacement");
                                }
                                issueRequestPacket.selectJobTasks = tasks;
                            }

                            butils.instance.post(process.env.VUE_APP_API_BASE_URL + "/atils/fulcrum/issue.json", issueRequestPacket)
                            .then((response)=>{
                                var result = response.data.result;
                                row.fulcrumLink = result.fulcrumRecordURL;
                                this.issueResults.completed.push(row);
                                this.process(index + 1);
                            })
                            .catch((err)=>{
                                // TODO: An Error Has OCcured Do Something Useful With it
                                console.log(err);
                                row._resolved = false;
                                this.issueResults.failed.push(row);
                                this.process(index + 1);
                            })
                        }
                    })
                    .catch((err)=>{
                        butils.createToast(this, 'Failed During Issue Check',`While checking if ${row["Device ID"]} has already been issued some issue occurred. Details are in the console.`);                        
                        // TODO: An Error Has Occurred Do Something Useful With it
                        console.log(err);

                        try
                        {
                            if (err.response && err.response.status === 500) {
                                const errorMessages = err.response.data.errors;
                                butils.createToast(
                                    this,
                                    'Server Error',
                                    `Error: ${errorMessages.join(', ') || 'Unknown error occurred.'}`
                                );
                            }
                        } catch (e) {
                            console.log(e);
                        }                       
                        
                        row._resolved = false;
                        this.issueResults.failed.push(row);
                        
                        setTimeout(() => {
                            this.process(index + 1);
                        }, 5000);
                    })

                
            }else{
                this.progress = this.items.length;
                this.weAreComplete = true;
            }
        },
        hasActiveRecord(rawIssueData){
            var result = false;
            rawIssueData.forEach((issue)=>{
                if(issue.app_id == this.appIDSelected){
                    // Potentially Still Qualifies as Out
                    var nonNullWebhookIDs = issue.wh_import_ids.filter((webhookID)=>{ return (webhookID != null) })
                    if(nonNullWebhookIDs.length == 0){
                        result = true;
                    }
                }
            })
            return result;
        },
        getActiveRecordData(row){
            var activeIssued = [];
            row.issueData.forEach((issue)=>{
                if(issue.app_id == this.appIDSelected){
                    // Potentially Still Qualifies as Out
                    var nonNullWebhookIDs = issue.wh_import_ids.filter((webhookID)=>{ return (webhookID != null) })
                    if(nonNullWebhookIDs.length == 0){
                        activeIssued.push(issue);
                    }
                }
            })
            return activeIssued;
        },
        issuedDataParser(){
            this.rawIssuedData.forEach((issue)=>{
                var tmpItem = {
                    fulcrumID: issue.fulcrum_id,
                    appID: issue.app_id,
                    issuedOn: this.formatDate(issue.issued_on),
                    deviceID: issue.device_id,
                    connectionID: issue.connection_id,
                    disabled: issue.disabled,
                    completed: false,
                    events: []
                };
                issue.wh_import_ids.forEach((impID, index)=>{
                    if(impID != null){
                        tmpItem.completed = true;
                        var tmpEvent = {
                            importID: impID,
                            eventID: issue.import_event_ids[index],
                            eventName: issue.import_event_names[index],
                            refID: issue.ref_ids[index],
                            imported: issue.import_dates[index],
                            eventTstamp: this.formatDate(issue.event_dates[index]),
                        };
                        tmpItem.events.push(tmpEvent)
                    }
                })
                this.issuedData.push(tmpItem);
                if(tmpItem.completed == false){
                    this.numberOfOutstandingIssued++;
                }
            })
            if(this.numberOfOutstandingIssued != 0){
                this.showHistory = true;
            }
            this.checkIssuedCompletedWithoutError = true;
            this.awaitingCheckIfIssued = false;
        },
        cancel(){
            this.issuable = false;
            this.issuableReason = 'Canceled By User, Reload Page to Restart';
        },
        checkIfIssuable(){
            // Check No Duplicate Device ID
            this.issuable = true;
            var counts = {};
            this.items.forEach((row)=>{
                if(_.has(counts, row["Device ID"])){
                    counts[row["Device ID"]]++;
                    this.issuable = false;
                    this.issuableReason = 'At least one Device ID is listed more than once in this list.'
                }else{
                    counts[row["Device ID"]] = 1;
                }
            });
        },
        filterOptionsAndBuildParentAndChildren(allOptions, parentName){
            this.status = `Gathering ${parentName} Field Options`;
            var build = {
                name: parentName, 
                expanded: false, 
                children: []
            };
            var buildableFields = allOptions.filter((opt)=>{ return opt.ui_group == parentName});
            buildableFields.forEach(fld => {
                build.children.push({
                    name: fld.display_name, 
                    dataName: fld.data_name, 
                    selected: false, 
                    id: fld.id, 
                    type: fld.type, 
                    parent: fld.ui_group, 
                    parent_id: fld.parent_id, 
                    parent_table: fld.parent_table,
                    selections: []
                });
            });
            return build;
        },
        loadSpecifiedList(listID){
            this.status = 'Fetching List Specification';
            this.waiting = true;
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/list_builder/saved/${listID}.json`)
                .then((listResp)=>{
                    var packed = listResp.data.result;
                    this.loadedFromPackage = packed;
                    // Load Save Package With Info
                    this.savePackage.id = packed.id;
                    this.savePackage.name = packed.name;
                    this.savePackage.description = packed.description;
                    this.savePackage.disabled = packed.disabled;
                    if(packed.global_shared){
                        this.savePackage.id = null;
                        this.savePackage.permissions = 'user';
                    }else if(packed.account_shared){
                        if(packed.allow_account_editing){
                            this.savePackage.permissions = 'accountEdit';
                        }else{
                            this.savePackage.permissions = 'account';
                        }
                    }
                    this.loadFromJSON(packed.list)
                })
                .catch((err)=>{
                    console.log(err);
                    butils.createToast(this,'Failed To Fetch Requested Saved List', 'Something went wrong when fetching the requested saved list.', 'danger');
                    this.waiting = false;
                })
        },
        loadFromJSON(json){
            this.status = 'Fetching List Specification';
            // console.log(`Loading From JSON`)
            this.waiting = true;
            // Clear Everything Out
            this.fields = [];
            this.items = [];
            this.filterElems = [];
            this.currentlySortedBy = null;
            this.sortBy = json.sortBy || null;
            this.sortDesc = json.sortDesc || false;
            // Check if device id exists in the set of fields
            var containsDeviceID = false;
            json.fields.forEach((fld)=>{
                if(fld.id == '36a239c1-8e04-4466-b456-cc86bed17fec'){ // UUID for DeviceID
                    containsDeviceID = true;
                } 
            });
            if(!containsDeviceID){
                var deviceIDItem = {
                    id: '36a239c1-8e04-4466-b456-cc86bed17fec',
                    position: json.fields.length,
                    search: null,
                    selections: []
                };
                json.fields.push(deviceIDItem);
            }
            this.tree.forEach((par)=>{
                par.expanded = false;
                // For Each Parent, Try To Find A Matching Child
                par.children.forEach((child)=>{
                    child.selected = false;
                    child.search = null;
                    child.selection = [];
                })
            })
            // Handle Each Field
            json.fields.forEach((inp)=>{
                var match = null;
                this.tree.forEach((par)=>{
                    // For Each Parent, Try To Find A Matching Child
                    par.children.forEach((child)=>{
                        if(child.id == inp.id){
                            match = child;
                            child.selected = true;
                            child.selections = inp.selections || [];
                            child.search = inp.search || null;
                        }
                    })
                })
                if(match != null){
                    this.addOrRemoveFromTableFields(match);
                }else{
                    // Did not find match for that id
                    console.log(`Could Not Find Match For Field ${inp.id} When Loading From Saved List`)
                }
            })

            // Visibility Does Not Apply In This Use Case
            // if(_.has(json, 'visibility')){
            //     this.filterVis = json.visibility.filter;
            //     this.mapCollapseVis = json.visibility.map;
            //     this.resultsVis = json.visibility.result;
            //     this.tree.forEach((parent)=>{
            //         if(_.contains(json.visibility.parents, parent.name)){
            //             parent.expanded = true;
            //         }
            //     })
            // }
            // Map Does Not Apply In This Use Case
            // if(_.has(json, 'map')){
            //     this.mapSelectedFeature = json.map.selectedFeature;
            //     this.mapColorBy = json.map.colorBy;
            // }

            this.filterElems = this.loadFilterRecursive(json.filters);
            this.waiting = false;
            // TODO: Conditional Logic to ensure that list pulled is sane
            if(this.fields.length > 0){
                this.fetch();
            }
        },
        loadFilterRecursive(filt){
            var res = [];
            filt.forEach((fl)=>{
                
                var tmp;
                if(fl.isGroup){
                    tmp = { 
                        id: butils.uuidv4(),
                        fieldName: null,
                        parent: null,
                        operatorName: null,
                        field: null,
                        operator: null,
                        value: null,
                        combination: fl.combination,
                        isGroup: fl.isGroup,
                        negate: ( (_.has(fl,'negate')) ? fl.negate : false ),
                        children: this.loadFilterRecursive(fl.children)
                    }
                    res.push(tmp);
                }else{
                    var field = this.rawAvailableFields.filter((avl)=>{ return avl.id == fl.field })[0];
                    var operator = this.filterOperatorsPerType[field.type].filter((fop)=>{ return fop.value == fl.operator; })[0];
                    tmp = { 
                        id: butils.uuidv4(),
                        fieldName: field.display_name,
                        parent: field.ui_group,
                        operatorName: operator.text,
                        field: fl.field,
                        operator: fl.operator,
                        value: fl.value,
                        combination: fl.combination,
                        isGroup: false,
                        children: []
                    };
                    res.push(tmp);
                }
            })
            return res;
        },
        addOrRemoveFromTableFields(child){
            //Check if removal is required
            var curPos = this.fields.indexOf(child);
            if(curPos != -1){
                var previousParents = _.uniq(_.pluck(this.fields, 'parent'));
                var postParents = _.uniq(_.pluck(this.fields.filter((fld)=>{ return fld != child; }), 'parent'));
                var diff = _.difference(previousParents, postParents);
                // console.log(diff);
                if(diff.length != 0){
                    // Removing this item will remove this parent, check if this alters a filter
                    if(this.filterElems.length > 0){
                        // It might alter a filter
                        var filtersReliantOnThisParent = this.filterElems.filter((flt)=>{return flt.parent == diff[0]});
                        if(filtersReliantOnThisParent.length != 0){
                            var result = confirm("Removing this field will remove one or more filters.");
                            if(result == true){
                                // The user is okay with removing those filters
                                this.filterElems = this.filterElems.filter((flt)=>{return flt.parent != diff[0]});
                                this.fields.splice(curPos, 1);
                            }else{
                                // The user doesnt want to remove those filters
                                child.selected = true;
                            }
                        }else{
                            // There are no filters that rely on this parent
                            this.fields.splice(curPos, 1);
                        }
                    }else{
                        // There are no filters, we can remove parents whenever we want.
                        this.fields.splice(curPos, 1);
                    }
                }else{
                    // No available parent changes
                    this.fields.splice(curPos, 1);
                }
                
            }else{
                // Its not here
                this.fields.push(child);
            }
        },
        packList(){
            var pack = {
                visibility: {
                    filter: this.filterVis,
                    map: this.mapCollapseVis,
                    result: this.resultsVis,
                    parents: []
                },
                map:{
                   selectedFeature: this.mapSelectedFeature,
                   colorBy: this.mapColorBy
                },
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                filters: [],
                fields: []
            };
            
            this.tree.forEach((parent)=>{
                if(parent.expanded){
                    pack.visibility.parents.push(parent.name);
                }
            })

            pack.filters = this.parseFilterRecursively(this.filterElems);

            this.fields.forEach((header, idx)=>{
                var tmp = {
                    id: header.id,
                    position: idx,
                    selections: header.selections,
                    search: header.search || null
                };
                pack.fields.push(tmp);
            });
            return pack;
        },
        parseFilterRecursively(elms){
            var packed = [];
            elms.forEach((elm, idx)=>{
                var tmp;
                if(elm.isGroup == true || elm.children.length > 0){
                    
                    tmp = {
                        position: idx,
                        field: elm.field,
                        operator: elm.operator,
                        value: elm.value,
                        combination: elm.combination,
                        isGroup: elm.isGroup,
                        negate: elm.negate,
                        children: this.parseFilterRecursively(elm.children)
                    };
                    if(elms.length - 1 == idx){ tmp.combination = 'NULL';}
                    packed.push(tmp);
                }else{
                    tmp = {
                        position: idx,
                        field: elm.field,
                        operator: elm.operator,
                        value: elm.value,
                        combination: elm.combination,
                        isGroup: elm.isGroup,
                        children: []
                    };
                    if(elms.length - 1 == idx){ tmp.combination = 'NULL';}
                    packed.push(tmp);
                }
            })
            return packed;
        },
        fetch(callback = null){
            this.status = `Running List: ${this.savePackage.name}`;
            this.waiting = true;
            var packed = this.packList();
            butils.instance.post(process.env.VUE_APP_API_BASE_URL + "/list_builder/run.json", packed)
            .then((resp)=>{
                var result = resp.data.result;
                this.rawItems = _.clone(result);
                butils.createToast(this, `${result.length} Results`, `Completed Fetch Succesfully with ${result.length} result rows`, 'warning', 10);

                this.localTableDataUpdate(callback);
                this.waiting = false;
                this.checkIfIssuable();
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                }else{
                    console.log(err);
                    butils.createToast(this, 'Failed To Fetch List', 'Something went wrong durring the list fetch process.', 'danger');
                    this.waiting = false;
                }
            })
        },
        localTableDataUpdate(callback = null){
            this.status = 'Formatting and Rendering List Results';
            this.waiting = true;
            // Make copy of items
            var tmpItems = _.clone(this.rawItems);
            // Fitler Rows By Header
            // Consider both the search term and the 
            this.fields.forEach((header)=>{
                // console.log(`Handling ${header.name}`)
                var hasSearch = false;
                var searchRegex = null;
                var hasSelections = false;
                if(_.has(header, 'search')){
                    if(header.search != null && header.search != ''){
                        // Search Contains Something, We should be looking for that
                        hasSearch = true;
                        searchRegex = new RegExp(header.search,'i');
                    }
                }
                if(_.has(header, 'selections')){
                    if(header.selections != null && header.selections.length > 0){
                        hasSelections = true;
                    }
                }
                if(hasSelections || hasSearch){
                    tmpItems = _.filter(tmpItems, (itm, idx)=>{ 
                        var match = false;
                        if(hasSearch){
                            if( itm[header.name] != null && itm[header.name].match(searchRegex) != null){
                                match = true;
                            }
                        }
                        if(hasSelections){
                            if( header.selections.includes(itm[header.name]) ){
                                match = true;
                            }
                        }
                        return match;
                    })
                }
            })
            // Update the items
            this.items = tmpItems;
            // Update the viewable items
            this.limitedItemsForDisplay = this.items.slice(0,this.setLimit);
            // Perform the sortBy
            this.performSortBy();
            // Limit visible on screen and handle correctly the more than limit rows, row.
            this.checkAndSetLimitValue();
            this.status = 'Done'
            this.waiting = false;
            if(callback != null){
                callback();
            }
        },
        performSortBy(){
            this.status = 'Sorting List Results';
            this.waiting = true;
            if(this.sortBy != null){
                if(this.currentlySortedBy == this.sortBy){
                    this.items = this.items.reverse();
                    this.limitedItemsForDisplay = this.items.slice(0,this.setLimit);
                }else{
                    // BUG: This can fail when more than one header has the same name
                    let matchingIndex = this.fields.findIndex((header)=>{ return header.name == this.sortBy; });
                    this.sortHeader = this.fields[matchingIndex];

                    if(this.sortHeader.type == 'numeric' || this.sortHeader.type == 'integer' || this.sortHeader.type == 'bigint'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = ( _.isNaN(a[this.sortBy]) || a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = ( _.isNaN(a[this.sortBy]) || b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                let aNum = Number(a[this.sortBy]);
                                let bNum = Number(b[this.sortBy]);
                                if(aNum == bNum){
                                    return 0;
                                }else{
                                    return (aNum < bNum) ? -1 : 1;
                                }
                                
                            }
                        }; 
                    }else if(this.sortHeader.type == 'text' || this.sortHeader.type == 'uuid' || this.sortHeader.type == 'timestamp with time zone' || this.sortHeader.type == 'character' || this.sortHeader.type == 'date'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                let res = a[this.sortBy].localeCompare(b[this.sortBy]);
                                if(res == 0){
                                    return 0;
                                }else{
                                    return (res < 0) ? -1 : 1;
                                }
                            }
                        }; 
                    }else if(this.sortHeader.type == 'text[]'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                let aStr = a[this.sortBy].toString();
                                let bStr = b[this.sortBy].toString();
                                let res = aStr.localeCompare(bStr);
                                if(res == 0){
                                    return 0
                                }else{
                                    return (res < 0) ? -1 : 1;
                                }
                            }
                        };
                    }else if(this.sortHeader.type == 'boolean'){
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else if(!b[this.sortBy]){
                                // if b is false, and not null.
                                // if a is not null, a can never be smaller than b
                                return 1;
                            }else if(b[this.sortBy] == a[this.sortBy]){
                                // if a == b, then a isnt smaller
                                return 0;
                            }else{
                                // b cannot be false, or null.
                                // a is not null, and isnt the same as b
                                // b is true, and a is false, therefore a < b is true
                                return -1;
                            }
                        };
                    }else if(this.sortHeader.type == 'photo' || this.sortHeader.type == 'attachment'){
                        // Does it exist, then by value
                        this.sortFunction = (a,b)=>{
                            // Compare a < b
                            let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                            let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                            if( aNull && bNull ){
                                return 0;
                            }else if( aNull ){
                                return -1;
                            }else if( bNull ){
                                return 1;
                            }else{
                                if(a[this.sortBy] == b[this.sortBy]){
                                    return 0;
                                }else{
                                    return (a[this.sortBy] < b[this.sortBy]) ? -1 : 1;
                                }
                            }
                        };
                    }

                    this.items.sort((a,b)=>{
                        return this.sortFunction(a,b)
                    });
                    if(this.sortDesc){
                        this.items = this.items.reverse();
                    }
                    this.currentlySortedBy = this.sortBy;
                    this.limitedItemsForDisplay = this.items.slice(0,this.setLimit);

                    // Quicksort Implimentation was depicated due to web browsers natively using efficient sorts with good runtimes and memory profiles.
                    // Well, except Safari, which uses a selection sort.
                }
            }
            this.waiting = false;
        },
        checkAndSetLimitValue(){
            if(this.items.length > this.setLimit){
                this.moreThanLimitItems = true;
            }else{
                this.moreThanLimitItems = false;
            }
        },
        fetchFormData(){
            this.fetchProductCodeOptions();
            var storedForms = this.$store.getters.getFulcrumFormStore;
            if(storedForms.lastFetched == null){
                this.requiresUpdate = true;
            }else if(Math.floor((new Date() - storedForms.lastFetched) / (1000*60*60*6)) > 0){
                // Last Fetched More Than 6 Hours Ago
                this.requiresUpdate = true;
            }

            if(this.requiresUpdate){
                butils.instance.get(process.env.VUE_APP_API_BASE_URL + "/atils/fulcrum/apps.json")
                .then((response)=>{
                    // console.log(response.data.result);
                    this.$store.commit('updateFulcrumForms', {forms: response.data.result});
                    this.forms = response.data.result;
                    this.requiresUpdate = false;
                    this.statusSelected = '_|AUTO|_';
                    this.parseForms();
                })
                .catch((err)=>{
                    console.log("An Error Occured While Fetching Fulcrum Forms")
                    // TODO: Handle Errors Here
                })
            }
        },
        fetchProductCodeOptions(){
            butils.instance.get(process.env.VUE_APP_API_BASE_URL + '/choices/product_codes.json?choices=true')
            .then(async (response) => {
                this.productCodeOptions = response.data.result.records;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        parseForms(){
            this.forms.forEach((f)=>{
                // var matchesBlacklist = f.name.match(/(design|template|sandbox|timesheet|vehicle)/gi);
                var matchesBlacklist = f.name.match(/(design|template|timesheet|vehicle)/gi);
                if(matchesBlacklist == null && _.keys(f.status).length != 0){
                    this.formNamesForDataList.push(f.name);
                }
            })
        },
        touchApp() {
            // Check if the appSelectedUpdated
            this.statusOptions = [];
            this.lastSelection = cloneDeep(this.appSelected);
            var selectedApp = this.forms.filter((form)=>{ return form.name == this.appSelected; });
            if(selectedApp.length == 1){
                // Perform Status Selection
                this.appSelectionValid = true;
                this.appIDSelected = selectedApp[0].id;
                var sOptKeys = _.keys(selectedApp[0].status);
                let automagic = {bgColor: "#99ff33", textColor: "#000000", label: "Automatic Status Selection", value: '_|AUTO|_'};
                this.statusOptions.push(automagic);
                sOptKeys.forEach((statusOpt)=>{
                    let bgColorValue = this.hexToRgb(selectedApp[0].status[statusOpt].color);
                    let textColor = null;
                    if ((bgColorValue.r*0.299 + bgColorValue.g*0.587 + bgColorValue.b*0.114) > 186){
                        // Use Dark Text
                        textColor = "#000000";
                    }else{
                        // Use Light Text
                        textColor = "#ffffff";
                    }
                    let altOpt = {bgColor: selectedApp[0].status[statusOpt].color, textColor: textColor, label: selectedApp[0].status[statusOpt].label, value: selectedApp[0].status[statusOpt].value};
                    this.statusOptions.push(altOpt);
                    this.touchStatus();
                })
                
            }else{
                // Invalid Selection
                this.appSelectionValid = false;
                this.statusOptions = [];
                this.statusSelected = null;
                this.updateReadyToIssue();
            }
        },
        touchStatus(){
            let validStatus = this.statusOptions.filter((status)=>{ return status.value == this.statusSelected; })
            if(validStatus.length == 1){
                // Is Valid
                this.statusSelectionValid = true;
                this.$store.commit('setFulcrumFormsStoreAccess', {lastSelectedForm: this.appSelected, lastSelectedStatus: this.statusSelected});
                this.updateReadyToIssue();
            }else{
                // Not Valid
                this.statusSelectionValid = false;
                this.updateReadyToIssue();
            }
        },
        touchOnConflict(){
            let validSelection = this.onConflictOptions.filter((action)=>{ return action.value == this.onConflictAction; })
            if(validSelection.length == 1){
                // Is Valid
                this.onConflictSelectionValid = true;
                this.updateReadyToIssue();
            }else{
                // Not Valid
                this.onConflictSelectionValid = false;
                this.updateReadyToIssue();
            }
        },
        touchProductCode(){
            let validProductCode = this.productCodeOptions.filter((pcode)=>{ return pcode.value == this.productCodeSelected; })
            if(validProductCode.length == 1){
                // Is Valid
                this.productSelectionValid = true;
                this.updateReadyToIssue();
            }else{
                // Not Valid
                this.productSelectionValid = false;
                this.updateReadyToIssue();
            }
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },
        updateReadyToIssue(){
            if(this.appSelectionValid && this.statusSelectionValid && this.productSelectionValid && this.onConflictSelectionValid){
                this.readyToIssue = true;
            }else{
                this.readyToIssue = false;
            }
        },
        formatDateWrapper(inp){
            return butils.formatters.timestampToDateTime(inp, true, false);
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.id = butils.uuidv4();
    },
    mounted(){
        this.status = 'Gathering Fields';
        this.fetchFormData();
        butils.instance.get(process.env.VUE_APP_API_BASE_URL + "/list_builder/fields.json")
        .then((resp)=>{
            var allOptions = resp.data.result;
            this.rawAvailableFields = allOptions;
            var groups = _.pluck(allOptions, "ui_group");
            groups = _.uniq(groups);
            
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Customer"));
            groups.splice(groups.indexOf("Customer"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Site"));
            groups.splice(groups.indexOf("Site"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Connection"));
            groups.splice(groups.indexOf("Connection"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Device"));
            groups.splice(groups.indexOf("Device"),1);
            
            groups.forEach(grp => {
                this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions, grp));
            });
            if(this.listID != null){
                // Fetch the list requested and load that into the system
                this.loadSpecifiedList(this.listID);
            }else{
                this.waiting = false;
            }
        })
        .catch((err)=>{
            console.log(err);
            butils.createToast(this,'Failed To Fetch Available Fields', 'Something went wrong when fetching the available fields from the server.', 'danger');
            this.waiting = false;
        })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
#collapse-results{
    width: calc(50vw - 30px);
    overflow-y: auto;
    overflow-x: auto;
    flex: 1;
}
div.fulcrum-info{
    padding-top: 10px;
    width: calc(50vw - 30px);
    overflow-y: auto;
    overflow-x: auto;
    flex: 1;
}
#fit-window{
    height: calc(100vh - 56px);
    width: calc(100vw - 30px);
    display: flex;
    flex-flow: row;
}
#table-holder{
    display: flex;
    flex-flow: column;
    max-height: calc(100vh - 56px);
    transition: all 0.3s;
    /* white-space: nowrap; */
}
.lb-table-th{
    white-space: nowrap;
}
.lb-table-td{
    overflow:visible;
    white-space: nowrap;
}
.hoverable:hover{
    background-color: lightgreen;
}
.hoverable{
    cursor:pointer;
}
.clickable{
    cursor: pointer;
}
.movable{
    cursor: move;
}
.expanded-child {
  border-left: 4px dotted black;
}
.scrollable{
    overflow-x: scroll;
    white-space: nowrap;
}
.tableheadernowrapping{
    white-space: nowrap;
}
th.delimited{
    border-left: 1px;
    border-left-style: solid;
    border-left-color: white;
}
div.drag-indicator{
    color: white;
    position: relative;
    top: 0;
    left: 0;
    border-left-color: lightgray;
    border-right-color:lightgray;
    border-left-width: 2px;
    border-right-width: 2px;
    border-left-style: solid;
    border-right-style: solid;
}
.header-inline{
    display: inline-block;
}
.scroll-over{
    height: 100%;
    overflow-y: scroll;
}
.filter-combinator-select{
    width: 10vw;
}
.text-match-drop-down{
    font-size: 1.25em;
    line-height: 2em;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
.child-form-head{
    width: 100%;
}
.more-than-row{
    background-color: yellow;
}
div.act-like-button{
    background-color: black;
}
div.act-like-button:hover{
    background-color: darkgray;
    color: gold;
}
.sticky-header{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1;
}
</style>